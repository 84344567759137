.homeGrid {
  padding: 30px;
}

.createNewButton {
  display: flex;
  justify-content: flex-end;
}

.logoutIcon {
  margin-left: 10px;
}

.languageButton {
  cursor: pointer;
}

.activeLanguage {
  text-decoration: underline;
}

.logo {
  max-width: 180px;
}
