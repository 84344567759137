.newUserGrid {
  width: 100%;
  padding: 16px;
  overflow-y: scroll;
}


.createButtonCol {
  display: flex;
  justify-content: flex-end;

  ion-button:first-child {
    height: 48px !important;
    width: 48px;
  }

  ion-button:not(:first-child) {
    width: 200px;
  }
}

.rowButtons {
  display: flex;
  padding-left: 10px;
  flex-grow: 0.5;
  align-items: flex-end;

  ion-button {
    --padding-end: 0;
    --padding-start: 0;
    height: 39px !important;
    width: 39px;
  }
}

.cancelIcon {
  margin-left: 10px;
  color: red;
}

.dropDiv {
  background: var(--ion-color-light);
  height: 200px;
  padding: 16px;
  border: 1px solid var(--ion-color-medium);
}

.noMargin {
  margin: 0;
}

.showResultRadio {
  display: flex;
  justify-content: flex-end;

  ion-radio {
    margin-left: 20px;
  }
}


.chip {
  padding: 20px 15px;
  font-weight: 500;
}

.activeChip {
  background: var(--ion-color-primary);
  color: var(--ion-color-white);
}

.editRow {
  margin-top: 40px;
}

.black {
  color: black;
}
