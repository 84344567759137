.listHeader, .listRow {
  ion-col {
    border: 1px solid var(--ion-color-medium);
    border-right: none;
    width: 7%;

    &:nth-child(2) {
      max-width: 8%;
    }
  }


  .iconCol {
    width: 5% !important;
    max-width: 5% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lastHeader {
    border-right: 1px solid var(--ion-color-medium);;
  }
}

.listHeader {
  background: var(--ion-color-module);

  p {
    color: var(--ion-color-white)
  }

  p:not(.noClick) {
    cursor: pointer;
  }
}

.listRow {
  ion-col {
    border-top: none;

    p {
      margin: 0;
    }
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pageCount {
  margin: 0 5px;
  cursor: pointer;
}

.activePage {
  font-weight: bold;
  color: var(--ion-color-primary);
}

.itemsOnPage {
  display: flex;
}

.big5_red {
  background-color: rgba(var(--ion-color-diamondRed-rgb), 0.5);
  cursor: revert;
}

.big5_blue {
  background-color: rgba(var(--ion-color-diamondBlue-rgb), 0.5);
  cursor: revert;
}

.big5_green {
  background-color: rgba(var(--ion-color-diamondGreen-rgb), 0.5);
  cursor: revert;
}

.editIcon {
  margin-left: 5px;
  cursor: pointer;
}

.even_row {
  background: var(--ion-color-disabled);
}

.ul_format {
  margin: 0px;
    padding: 0px 0px 0px 16px;
}

.export {
  text-align: center;
  position: relative;
  top: 12px;

    a:hover {
      cursor: pointer;
    }
}
