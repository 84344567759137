.evaluationChangerGrid {
  width: 100%;
  max-width: 600px;
  padding: 20px;
}

.range_col {
  padding: 5px 0;
}

.range {
  --knob-size: 18px;
  padding-top: 0;
  padding-bottom: 0;
}

.label {
  padding: 2px 0;
}

.saveButtonCol {
  display: flex;
  justify-content: flex-end;
}
