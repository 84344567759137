.loginGrid {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  width: 30%;
  min-width: 360px;
  height: 100%;
  color: var(--ion-color-white);
}

.loginContent {
  --background: var(--ion-color-background);
}

.logo {
  max-width: 180px;
}

.logo_big {
  display: flex;
  justify-content: center;

  img {
    width: 200px;
    height: 200px;
  }
}
