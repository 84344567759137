.searchBarRow {
  display: flex;

  ion-col {
    display: flex;
    align-items: center;

    p {
      margin: 0 10px;
    }
  }
}


.searchInput {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  height: 42px;
}

.searchButton {
  height: 42px;
  width: 42px;
  background: var(--ion-color-primary);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: var(--ion-color-white);
  cursor: pointer;
  font-size: 24px;
}

.groupAllInput {
  margin-left: 15px;
}


.batchCol {
  justify-content: space-between;

  div {
    display: flex;
    align-content: center;
    align-items: center;
  }
}

.flexCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;

  ion-input {
    margin: 10px 0;
  }
}
