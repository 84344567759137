:root {
    /*global variables*/
    --ion-text-color: var(--ion-color-text);
    --ion-font-family: "Raleway";

    /*colors*/
    --ion-color-primary: #E2001A;
    --ion-color-primary-rgb: 226, 0, 26;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #c70017;
    --ion-color-primary-tint: #e51a31;

    --ion-color-secondary: #4a4a4a;
    --ion-color-secondary-rgb: 74, 74, 74;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #414141;
    --ion-color-secondary-tint: #5c5c5c;

    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;

    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;

    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;

    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #595959;
    --ion-color-medium-rgb: 89, 89, 89;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #4e4e4e;
    --ion-color-medium-tint: #6a6a6a;

    --ion-color-light: #f9f9f9;
    --ion-color-light-rgb: 249, 249, 249;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #dbdbdb;
    --ion-color-light-tint: #fafafa;

    --ion-color-text: #052330;
    --ion-color-text-rgb: 5, 35, 48;
    --ion-color-text-contrast: #ffffff;
    --ion-color-text-contrast-rgb: 255, 255, 255;
    --ion-color-text-shade: #041f2a;
    --ion-color-text-tint: #1e3945;

    --ion-color-white: #ffffff;
    --ion-color-white-rgb: 255, 255, 255;
    --ion-color-white-contrast: #000000;
    --ion-color-white-contrast-rgb: 0, 0, 0;
    --ion-color-white-shade: #e0e0e0;
    --ion-color-white-tint: #ffffff;

    --ion-color-disabled: #CECFD1;
    --ion-color-disabled-rgb: 206, 207, 209;
    --ion-color-disabled-contrast: #ffffff;
    --ion-color-disabled-contrast-rgb: 0, 0, 0;
    --ion-color-disabled-shade: #b5b6b8;
    --ion-color-disabled-tint: #d3d4d6;

    --ion-color-module: #052330;
    --ion-color-module-rgb: 5, 35, 48;
    --ion-color-module-contrast: #ffffff;
    --ion-color-module-contrast-rgb: 255, 255, 255;
    --ion-color-module-shade: #041f2a;
    --ion-color-module-tint: #1e3945;

    --ion-color-module-inner-background: #1E3A45;
    --ion-color-module-inner-background-rgb: 30, 58, 69;
    --ion-color-module-inner-background-contrast: #ffffff;
    --ion-color-module-inner-background-contrast-rgb: 255, 255, 255;
    --ion-color-module-inner-background-shade: #1a333d;
    --ion-color-module-inner-background-tint: #354e58;


    --ion-color-fixedHeaderBlue: #052330;
    --ion-color-fixedHeaderBlue-rgb: 5, 35, 48;
    --ion-color-fixedHeaderBlue-contrast: #ffffff;
    --ion-color-fixedHeaderBlue-contrast-rgb: 255, 255, 255;
    --ion-color-fixedHeaderBlue-shade: #041f2a;
    --ion-color-fixedHeaderBlue-tint: #1e3945;

    --ion-color-diamondRed: #FF1F33;
    --ion-color-diamondRed-rgb: 255, 31, 51;
    --ion-color-diamondRed-contrast: #ffffff;
    --ion-color-diamondRed-contrast-rgb: 255, 255, 255;
    --ion-color-diamondRed-shade: #e01b2d;
    --ion-color-diamondRed-tint: #ff3547;


    --ion-color-diamondGreen: #FFDB00;
    --ion-color-diamondGreen-rgb: 255, 219, 0;
    --ion-color-diamondGreen-contrast: #000000;
    --ion-color-diamondGreen-contrast-rgb: 0, 0, 0;
    --ion-color-diamondGreen-shade: #e0c100;
    --ion-color-diamondGreen-tint: #ffdf1a;


    --ion-color-diamondBlue: #007DFF;
    --ion-color-diamondBlue-rgb: 0, 125, 255;
    --ion-color-diamondBlue-contrast: #ffffff;
    --ion-color-diamondBlue-contrast-rgb: 255, 255, 255;
    --ion-color-diamondBlue-shade: #006ee0;
    --ion-color-diamondBlue-tint: #1a8aff;


    --ion-color-headerRed: linear-gradient(60deg, rgba(255, 0, 0, 1) 0%, rgba(194, 46, 61, 1) 100%);
    --ion-color-headerRed-rgb: 255, 0, 0;
    --ion-color-headerRed-contrast: #ffffff;
    --ion-color-headerRed-contrast-rgb: 255, 255, 255;
    --ion-color-headerRed-shade: #e00000;
    --ion-color-headerRed-tint: #ff1a1a;

    --ion-color-headerBlue: #1a1557;
    --ion-color-headerBlue-rgb: 26, 21, 87;
    --ion-color-headerBlue-contrast: #ffffff;
    --ion-color-headerBlue-contrast-rgb: 255, 255, 255;
    --ion-color-headerBlue-shade: #17124d;
    --ion-color-headerBlue-tint: #312c68;

    /*custom colors*/
    --ion-color-background: linear-gradient(145deg, rgba(27, 21, 87, 1) 30%, rgba(255, 0, 22, 1) 100%);
    --ion-color-background_2: linear-gradient(145deg, rgba(27, 21, 87, 1) 50%, rgba(255, 0, 22, 1) 100%);
    --ion-color-dark-background: linear-gradient(180deg, rgba(90, 90, 90, 1) 0%, rgba(0, 0, 0, 1) 100%);
    --ion-color-purple-background: linear-gradient(135deg, rgba(27, 21, 87, 1) 0%, rgba(0, 0, 0, 1) 100%);
    --ion-color-button: linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(192, 39, 45, 1) 100%);
    --ion-color-module-training-item-background: #2A444F;
    --ion-color-icons: #0D232F;
    --ion-color-big5: #f9f9f9;
    --ion-color-big5-wrapper: #DADEE0;
}

.ion-color-text {
    --ion-color-base: var(--ion-color-text) !important;
    --ion-color-base-rgb: var(--ion-color-text-rgb) !important;
    --ion-color-contrast: var(--ion-color-text-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-text-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-text-shade) !important;
    --ion-color-tint: var(--ion-color-text-tint) !important;
}

.ion-color-white {
    --ion-color-base: var(--ion-color-white) !important;
    --ion-color-base-rgb: var(--ion-color-white-rgb) !important;
    --ion-color-contrast: var(--ion-color-white-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-white-shade) !important;
    --ion-color-tint: var(--ion-color-white-tint) !important;
}

.ion-color-disabled {
    --ion-color-base: var(--ion-color-disabled) !important;
    --ion-color-base-rgb: var(--ion-color-disabled-rgb) !important;
    --ion-color-contrast: var(--ion-color-disabled-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-disabled-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-disabled-shade) !important;
    --ion-color-tint: var(--ion-color-disabled-tint) !important;
}

.ion-color-module {
    --ion-color-base: var(--ion-color-module) !important;
    --ion-color-base-rgb: var(--ion-color-module-rgb) !important;
    --ion-color-contrast: var(--ion-color-module-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-module-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-module-shade) !important;
    --ion-color-tint: var(--ion-color-module-tint) !important;
}

.ion-color-module-inner-background {
    --ion-color-base: var(--ion-color-module-inner-background) !important;
    --ion-color-base-rgb: var(--ion-color-module-inner-background-rgb) !important;
    --ion-color-contrast: var(--ion-color-module-inner-background-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-module-inner-background-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-module-inner-background-shade) !important;
    --ion-color-tint: var(--ion-color-module-inner-background-tint) !important;
}

.ion-color-fixedHeaderBlue {
    --ion-color-base: var(--ion-color-fixedHeaderBlue) !important;
    --ion-color-base-rgb: var(--ion-color-fixedHeaderBlue-rgb) !important;
    --ion-color-contrast: var(--ion-color-fixedHeaderBlue-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-fixedHeaderBlue-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-fixedHeaderBlue-shade) !important;
    --ion-color-tint: var(--ion-color-fixedHeaderBlue-tint) !important;
}

.ion-color-headerRed {
    --ion-color-base: var(--ion-color-headerRed) !important;
    --ion-color-base-rgb: var(--ion-color-headerRed-rgb) !important;
    --ion-color-contrast: var(--ion-color-headerRed-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-headerRed-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-headerRed-shade) !important;
    --ion-color-tint: var(--ion-color-headerRed-tint) !important;
}

.ion-color-diamondRed {
    --ion-color-base: var(--ion-color-diamondRed) !important;
    --ion-color-base-rgb: var(--ion-color-diamondRed-rgb) !important;
    --ion-color-contrast: var(--ion-color-diamondRed-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-diamondRed-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-diamondRed-shade) !important;
    --ion-color-tint: var(--ion-color-diamondRed-tint) !important;
}

.ion-color-diamondGreen {
    --ion-color-base: var(--ion-color-diamondGreen);
    --ion-color-base-rgb: var(--ion-color-diamondGreen-rgb);
    --ion-color-contrast: var(--ion-color-diamondGreen-contrast);
    --ion-color-contrast-rgb: var(--ion-color-diamondGreen-contrast-rgb);
    --ion-color-shade: var(--ion-color-diamondGreen-shade);
    --ion-color-tint: var(--ion-color-diamondGreen-tint);
}

.ion-color-diamondBlue {
    --ion-color-base: var(--ion-color-diamondBlue) !important;
    --ion-color-base-rgb: var(--ion-color-diamondBlue-rgb) !important;
    --ion-color-contrast: var(--ion-color-diamondBlue-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-diamondBlue-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-diamondBlue-shade) !important;
    --ion-color-tint: var(--ion-color-diamondBlue-tint) !important;
}

.ion-color-headerBlue {
    --ion-color-base: var(--ion-color-headerBlue) !important;
    --ion-color-base-rgb: var(--ion-color-headerBlue-rgb) !important;
    --ion-color-contrast: var(--ion-color-headerBlue-contrast) !important;
    --ion-color-contrast-rgb: var(--ion-color-headerBlue-contrast-rgb) !important;
    --ion-color-shade: var(--ion-color-headerBlue-shade) !important;
    --ion-color-tint: var(--ion-color-headerBlue-tint) !important;
}

/*global styling */

.createNewModal {
    --height: 100%;
    --width: 100%;
    color: black;
}

.evaluationModal {
    color: black;
}

input {
    background-color: white;
}


ion-button {
    height: 48px !important;
    --border-radius: 24px !important;
    text-transform: none;
    cursor: pointer;
}

ion-button:disabled {
    cursor: not-allowed;
}

ion-input, ion-select, ion-datetime {
    border: 1px solid grey;
    border-radius: 5px;
}

.react-datepicker-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 150px;
}

.react-datepicker-wrapper > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 1px solid grey;
    border-radius: 5px;
    max-width: 150px;
}

.react-datepicker-wrapper > div > input {
    border: none;
    max-width: 120px;
}

.pointer {
    cursor: pointer;
}


/********************************************************
****************** BIG 5 PERSONALITY TEXT****************
********************************************************/
.personality_red {
    color: var(--ion-color-diamondRed);
}

.personality_blue {
    color: var(--ion-color-diamondBlue);
}

.personality_green {
    color: #C9A700;
}

.personality_header {
    font-size: 20px;
    letter-spacing: 0.8px;
}

.personality_text {
    font-size: 14px;
    line-height: 20px;
}


/*Raleway font*/
@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Black.ttf');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-BlackItalic.ttf');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-ExtraBoldItalic.ttf');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}


@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Light.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-ExtraLight.ttf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-ExtraLightItalic.ttf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-Thin.ttf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('Raleway-ThinItalic.ttf');
    font-weight: 100;
    font-style: italic;
}
